import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import moment from 'moment';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private titleService: Title,
  ) { }

  user;

  authLogin(id, user, cb) {
    const that = this;

    this.apiService.signInEcommerce(id, user).subscribe((res) => {
      if (!res['error']) {
        localStorage.setItem('authToken', res['token']);
        localStorage.setItem('expirationToken', res['expiration']);
        localStorage.setItem('user', JSON.stringify(res['user']));

        return cb({
          error: false,
          message: 'Usuário logado com sucesso!',
        });
      }

      return cb({
        error: true,
        message: res['message'],
      });
    });
  }

  isLoggedIn(): boolean {
    const tokenDateLocalStorage = new Date(localStorage.getItem('expirationToken') || new Date());
    const authTokenLocalStorage = localStorage.getItem('authToken') || 'null';
    const userLocalStorage = localStorage.getItem('user') || 'null';

    return (authTokenLocalStorage !== null && tokenDateLocalStorage > new Date()) ? true : false;
  }

  verifyLoggedIn() {
    const tokenDateLocalStorage = new Date(localStorage.getItem('expirationToken') || new Date());
    const authTokenLocalStorage = localStorage.getItem('authToken') || 'null';
    const userLocalStorage = localStorage.getItem('user') || 'null';

    if (new Date() > tokenDateLocalStorage) {
      this.authLogout();
    }
  }

  authLogout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('expirationToken');
    localStorage.removeItem('user');
  }
  
  async getEcommerceStatus(urlBase) {
    let status;

    const resConfigs = await this.apiService.getB2bConfigsByUrl('?url_base=' + urlBase)
      .toPromise()
      .catch(error => {
        window.location.href = '/home';

        console.log('Errso: ', error);
      });
      
    if (resConfigs) {
      if (resConfigs['configs']) {
        if (resConfigs['configs']['store_status'] == 2) {
          status = true;
        }
      }
    }
    
    return status;
  }

  getUserEmail() {
    if (localStorage.getItem('user') !== null) {
      const user = JSON.parse(localStorage.getItem('user'));

      return user.email;
    }

    return null;
  }

  // Método Principal para pegar configurações da Empresa e salvar no Storage
  async getInfosByUrl(urlBase) {
    const updatedAtLocalStorage = new Date(this.localStorageService.get('updated_at') || new Date());
    const actualDate = moment(new Date()).toDate();

    // Verifica se existe variáveis local e sua última data de atualização
    if (this.localStorageService.ifExists() && actualDate < updatedAtLocalStorage) {
      // console.log('KAMDJBKANSHAHDKA');
    } else {
      // Nova metodologia utilizando AWAIT
      const resConfigs = await this.apiService.getB2bConfigsByUrl(urlBase)
        .toPromise()
        .catch(error => {
          this.authLogout();

          window.location.href = '/home';

          console.log('Errso: ', error);
        });

      // Retorno da Função ERRO? ?
      if (resConfigs) {
        if (resConfigs['error']) {
          console.error('Ocorreu um erro ao carregar as configurações da empresa! #001\n', resConfigs['message']);
        } else {
          // Configurações de Retorno
          if (resConfigs['configs']) {
            // Ícone
            if (this.localStorageService.isKeyNull('store_favicon')) {
              this.localStorageService.set('store_favicon', resConfigs['configs']['store_favicon']);
            }

            // Logotipo
            if (this.localStorageService.isKeyNull('store_logo')) {
              this.localStorageService.set('store_logo', resConfigs['configs']['store_logo']);
            }

            // Popup
            if (this.localStorageService.isKeyNull('store_popup_image')) {
              this.localStorageService.set('store_popup_image', resConfigs['configs']['store_popup_image']);
            }

            // Cor Inicial
            if (this.localStorageService.isKeyNull('store_primary_color')) {
              this.localStorageService.set('store_primary_color', resConfigs['configs']['store_primary_color']);
            }

            // Id Relacional
            if (this.localStorageService.isKeyNull('pd_id')) {
              this.localStorageService.set('pd_id', resConfigs['configs']['id']);
            }

            // Id Firebase
            if (this.localStorageService.isKeyNull('biaction_id')) {
              this.localStorageService.set('biaction_id', resConfigs['configs']['biaction_id']);
            }

            // Nome
            if (this.localStorageService.isKeyNull('store_name')) {
              this.localStorageService.set('store_name', resConfigs['configs']['store_name']);
            }

            // Banners
            if (this.localStorageService.isKeyNull('banners')) {
              this.localStorageService.set('banners', resConfigs['configs']['banners'] || '');
            }

            // Telefone
            if (this.localStorageService.isKeyNull('phone_number_1')) {
              this.localStorageService.set('phone_number_1', resConfigs['configs']['phone_number_1'] || '');
            }

            // Celular
            if (this.localStorageService.isKeyNull('mobile_number_1')) {
              this.localStorageService.set('mobile_number_1', resConfigs['configs']['store_phone_number'] || '');
            }

            // Whatsapp
            if (this.localStorageService.isKeyNull('mobile_number_2')) {
              this.localStorageService.set('mobile_number_2', resConfigs['configs']['store_whatsapp_number'] || '');
            }

            // Facebook
            if (this.localStorageService.isKeyNull('facebook_link')) {
              this.localStorageService.set('facebook_link', resConfigs['configs']['facebook_link'] || '');
            }

            // Instagram
            if (this.localStorageService.isKeyNull('instagram_link')) {
              this.localStorageService.set('instagram_link', resConfigs['configs']['instagram_link'] || '');
            }

            // Twitter
            if (this.localStorageService.isKeyNull('twitter_link')) {
              this.localStorageService.set('twitter_link', resConfigs['configs']['twitter_link'] || '');
            }

            // Linkedin
            if (this.localStorageService.isKeyNull('linkedin_link')) {
              this.localStorageService.set('linkedin_link', resConfigs['configs']['linkedin_link'] || '');
            }

            // Email
            if (this.localStorageService.isKeyNull('store_mail_from_email')) {
              this.localStorageService.set('store_mail_from_email', resConfigs['configs']['store_mail_from_email'] || '');
            }

            // Cidade
            if (this.localStorageService.isKeyNull('address_city')) {
              this.localStorageService.set('address_city', resConfigs['configs']['address_city'] || '');
            }

            // Estado
            if (this.localStorageService.isKeyNull('address_state')) {
              this.localStorageService.set('address_state', resConfigs['configs']['address_state'] || '');
            }

            //categorias selecionadas com login
            if (this.localStorageService.isKeyNull('main_categories')) {
              this.localStorageService.set('main_categories', resConfigs['configs']['main_categories'] || '');
            }

            //categorias selecionadas sem login
            if (this.localStorageService.isKeyNull('no_user_categories')) {
              this.localStorageService.set('no_user_categories', resConfigs['configs']['no_user_categories'] || '');
            }

            // Tipo de Acesso
            if (this.localStorageService.isKeyNull('store_access')) {
              this.localStorageService.set('store_access', resConfigs['configs']['store_access'] || 2);
            }

            // Data de Atualização
            this.localStorageService.set('updated_at', moment(new Date()).add(2, 'hours').toDate());
          } else {
            console.error('Ocorreu um erro ao carregar as configurações da empresa! #002\n', resConfigs['message']);
          }
        }
      }
    }

  }

  // Método para setar as configurações do Ecommerce
  setEcommerceConfigs() {
    let ecommerceConfigs = {};

    // Seta as Configurações na Variável Auxiliar
    ecommerceConfigs['store_favicon'] = this.localStorageService.get('store_favicon');
    ecommerceConfigs['store_logo'] = this.localStorageService.get('store_logo');
    ecommerceConfigs['store_popup_image'] = this.localStorageService.get('store_popup_image');
    ecommerceConfigs['store_access'] = this.localStorageService.get('store_access');
    ecommerceConfigs['store_primary_color'] = this.localStorageService.get('store_primary_color');
    ecommerceConfigs['pd_id'] = this.localStorageService.get('pd_id');
    ecommerceConfigs['biaction_id'] = this.localStorageService.get('biaction_id');
    ecommerceConfigs['store_name'] = this.localStorageService.get('store_name');
    ecommerceConfigs['banners'] = this.localStorageService.get('banners');
    ecommerceConfigs['link_image_popup'] = this.localStorageService.get('link_image_popup');
    ecommerceConfigs['phone_number_1'] = this.localStorageService.get('phone_number_1');
    ecommerceConfigs['mobile_number_1'] = this.localStorageService.get('mobile_number_1');
    ecommerceConfigs['facebook_link'] = this.localStorageService.get('facebook_link');
    ecommerceConfigs['instagram_link'] = this.localStorageService.get('instagram_link');
    ecommerceConfigs['linkedin_link'] = this.localStorageService.get('linkedin_link');
    ecommerceConfigs['twitter_link'] = this.localStorageService.get('twitter_link');
    ecommerceConfigs['main_categories'] = this.localStorageService.get('main_categories');
    ecommerceConfigs['no_user_categories'] = this.localStorageService.get('no_user_categories');
    ecommerceConfigs['email_1'] = this.localStorageService.get('store_mail_from_email');
    ecommerceConfigs['address_city'] = this.localStorageService.get('address_city');
    ecommerceConfigs['address_state'] = this.localStorageService.get('address_state');
    ecommerceConfigs['show_banner'] = this.localStorageService.get('show_banner');

    // Seta o Título da Empresa
    this.titleService.setTitle(ecommerceConfigs['store_name']);

    return ecommerceConfigs;
  }
}
