import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';

declare const $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  clientId;
  biactionId;

  user = {
    phone_number_1: '',
    mobile_number_1: '',
    doc_type: '',
    pf_name: '',
    pf_doc_number: '',
    pf_reg_number: '',
    pj_company_name: '',
    pj_name: '',
    pj_doc_number: '',
    pj_reg_number: '',
    user_name: '',
    email_1: '',
    user_password: '',
    address_street: '',
    address_number: '',
    address_more: '',
    address_zipcode: '',
    address_district: '',
    address_city: '',
    address_state: '',
    address_street_delivery: '',
    address_number_delivery: '',
    address_more_delivery: '',
    address_zipcode_delivery: '',
    address_district_delivery: '',
    address_city_delivery: '',
    address_state_delivery: '',
    phone_number_2: '',
    mobile_number_2: '',
  };

  settings = {
    options: [],
    address: 1,
    address_delivery: 1,
    contact: 1,
    address_mandatory: 0,
    address_delivery_mandatory: 0,
    contact_mandatory: 0,
    basic_data_mandatory: 1,
    doc_type: 'both'
  };

  available = {
    name_pf: 0,
    doc_number_pf: 0,
    reg_number_pf: 0,
    legal_name: 0,
    name: 0,
    doc_number: 0,
    reg_number: 0,
    user_name: 0,
    user_email: 1,
    user_password: 1,
    address: 1,
    address_delivery: 0,
    phone_number_1: 0,
    phone_number_2: 0,
    mobile_number_1: 0,
    mobile_number_2: 0,
    contact: 0,
  };
  mandatory = {
    name_pf: 0,
    doc_number_pf: 0,
    reg_number_pf: 0,
    legal_name: 0,
    name: 0,
    doc_number: 0,
    reg_number: 0,
    user_name: 0,
    user_email: 1,
    user_password: 1,
    address: 1,
    address_delivery: 0,
    phone_number_1: 0,
    phone_number_2: 0,
    mobile_number_1: 0,
    mobile_number_2: 0,
    contact: 0,
  };

  states = [
    { 'name': 'AL' }, { 'name': 'AP' }, { 'name': 'AM' }, { 'name': 'BA' }, { 'name': 'CE' }, { 'name': 'DF' }, { 'name': 'ES' },
    { 'name': 'GO' }, { 'name': 'MA' }, { 'name': 'MT' }, { 'name': 'MS' }, { 'name': 'MG' }, { 'name': 'PA' }, { 'name': 'PB' },
    { 'name': 'PR' }, { 'name': 'PE' }, { 'name': 'PI' }, { 'name': 'RJ' }, { 'name': 'RN' }, { 'name': 'RS' }, { 'name': 'RO' },
    { 'name': 'RR' }, { 'name': 'SC' }, { 'name': 'SP' }, { 'name': 'SE' }, { 'name': 'TO' },
  ];
  customFields = [];

  logoUrl = '';
  docType = '';

  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private salePolicyService: SalePolicyService,
  ) {
    this.clientId = this.localStorageService.get('pd_id');
    this.biactionId= this.localStorageService.get('biaction_id');
  }

  ngOnInit() {
    this.getClientData();
    this.getStoreCustomFields();
    this.maskFields();
  }

  getClientData() {
    this.apiService.getB2bConfigs('?client_id=' + 108).subscribe((res: any) => {
      if (!res['error']) {
        this.logoUrl = `https://p.biaction.com.br/${res['configs']['id']}/${res['configs']['store_logo']}`;

        if (res['configs']['store_custom_field_settings']) {
          this.settings = res['configs']['store_custom_field_settings'];
        }
        this.getEcommerceModuleConfigs();
      }
    });
  }
  
  getEcommerceModuleConfigs() {
    this.salePolicyService.getEcommercePolice(this.biactionId).then((res) => {
      if (!res['error']) {
        this.settings.doc_type = res['doc_type'];

        const available = res['register_client_fields_available'];
        const mandatory = res['register_client_fields_mandatory'];

        if (available) {
          Object.keys(this.available).forEach(key => {
            if (available.includes(key)) {
              this.available[key] = 1;
            } else {
              this.available[key] = 0;
            }
          });
        }

        if (mandatory) {
          Object.keys(this.mandatory).forEach(key => {
            if (mandatory.includes(key)) {
              this.mandatory[key] = 1;
            } else {
              this.mandatory[key] = 0;
            }
          });
        }

        if (this.available.phone_number_1 === 1 ||
          this.available.phone_number_2 === 1 ||
          this.available.mobile_number_1 === 1 ||
          this.available.mobile_number_2 === 1) 
        {
          this.available.contact = 1;
        } else {
          this.available.contact = 0;
        }
      } else {
        this.toastrService.showError(res['message']);
      }
    });
  }

  getStoreCustomFields() {
    this.apiService.getStoreCustomFields('?client_id=' + this.clientId).subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao recuperar campos personalizados do cliente!: ', res['error']);
      } else {
        this.customFields = res['storeCustomFields'];

        this.customFields.forEach((item) => {
          if (item.options) {
            item.options = JSON.parse(item.options);
          }
        });
      }
    });
  }

  maskFields() {
    setTimeout(() => {
      $('.phone-number').mask('(99) 99999-999?9');
    }, 1000);
  }

  changeDocType() {
    const docType = $('#doc_type').val();

    if (docType === 'cpf') {
      this.docType = 'cpf';
      this.user.doc_type = 'cpf';

      setTimeout(() => {
        $('#pf_doc_number').mask('999.999.999-99');
      }, 500);
    } else if (docType === 'cnpj') {
      this.docType = 'cnpj';
      this.user.doc_type = 'cnpj';

      setTimeout(() => {
        $('#pj_doc_number').mask('99.999.999/9999-99');
      }, 500);
    } else {
      this.docType = '';
      this.user.doc_type = '';
    }
  }

  save() {
    const btn = $('#save');

    let msg = '';

    this.user = {
      phone_number_1: $('#phone_number_1').val(),
      mobile_number_1: $('#mobile_number_1').val(),
      doc_type: $('#doc_type').val(),
      pf_name: $('#pf_name').val(),
      pf_doc_number: $('#pf_doc_number').val(),
      pf_reg_number: $('#pf_reg_number').val(),
      pj_company_name: $('#pj_company_name').val(),
      pj_name: $('#pj_name').val(),
      pj_doc_number:  $('#pj_doc_number').val(),
      pj_reg_number: '',
      user_name: $('#user_name').val(),
      email_1: $('#email_1').val(),
      user_password: $('#user_password').val(),
      address_street: $('#address_street').val(),
      address_number: $('#address_more').val(),
      address_more: $('#address_number').val(),
      address_zipcode: $('#address_zipcode').val(),
      address_district: $('#address_district').val(),
      address_city: $('#address_city').val(),
      address_state: $('#address_state').val(),
      address_street_delivery: $('#address_street_delivery').val(),
      address_number_delivery: $('#address_number_delivery').val(),
      address_more_delivery: $('#address_more_delivery').val(),
      address_zipcode_delivery: $('#address_zipcode_delivery').val(),
      address_district_delivery: $('#address_district_delivery').val(),
      address_city_delivery: $('#address_city_delivery').val(),
      address_state_delivery: $('#address_state_delivery').val(),
      phone_number_2: $('#phone_number_2').val(),
      mobile_number_2: $('#mobile_number_2').val(),
    };

    if (this.mandatory.phone_number_1 && $('#phone_number_1').val() === '') {
      msg += 'O campo <b>Telefone</b> é obrigatório! <br>';
    }

    if (this.mandatory.mobile_number_1 && $('#mobile_number_1').val() === '') {
      msg += 'O campo <b>Celular</b> é obrigatório! <br>';
    }

    if (this.mandatory.phone_number_2 && $('#phone_number_2').val() === '') {
      msg += 'O campo <b>Telefone 2</b> é obrigatório! <br>';
    }

    if (this.mandatory.mobile_number_2 && $('#mobile_number_2').val() === '') {
      msg += 'O campo <b>Celular 2</b> é obrigatório! <br>';
    }

    if (this.docType === '') {
      msg += 'O campo <b>Tipo de Cliente</b> é obrigatório! <br>';
    } else if (this.docType === 'cpf') {
      if (this.mandatory.name_pf && $('#pf_name').val() === '') {
        msg += 'O campo <b>Nome Completo</b> é obrigatório! <br>';
      }

      if (this.mandatory.doc_number_pf && $('#pf_doc_number').val() === '') {
        msg += 'O campo <b>CPF</b> é obrigatório! <br>';
      }

      if (this.mandatory.reg_number_pf && $('#pf_reg_number').val() === '') {
        msg += 'O campo <b>RG</b> é obrigatório! <br>';
      }
    } else if (this.docType === 'cnpj') {
      if (this.mandatory.legal_name && $('#pj_company_name').val() === '') {
        msg += 'O campo <b>Razão Social</b> é obrigatório! <br>';
      }

      if (this.mandatory.name &&  $('#pj_name').val() === '') {
        msg += 'O campo <b>Nome Fantasia</b> é obrigatório! <br>';
      }

      if (this.mandatory.doc_number &&  $('#pj_doc_number').val() === '') {
        msg += 'O campo <b>CNPJ</b> é obrigatório! <br>';
      }

      if (this.mandatory.reg_number &&  $('#pj_reg_number').val() === '') {
        msg += 'O campo <b>Inscrição Estadual</b> é obrigatório! <br>';
      }
    }

    if (this.mandatory.user_name && $('#user_name').val() === '') {
      msg += 'O campo <b>Nome de Usuário</b> é obrigatório! <br>';
    }

    if ($('#email_1').val() === '') {
      msg += 'O campo <b>E-mail</b> é obrigatório! <br>';
    }

    if ($('#user_password').val() === '') {
      msg += 'O campo <b>Senha</b> é obrigatório! <br>';
    }

    if (this.mandatory.address) {
      if ($('#address_street').val() === '') {
        msg += 'O campo <b>Logradouro</b> do endereço de cobrança é obrigatório! <br>';
      }
  
      if ($('#address_number').val() === '') {
        msg += 'O campo <b>Número</b> do endereço de cobrança é obrigatório! <br>';
      }
  
      if ($('#address_zipcode').val() === '') {
        msg += 'O campo <b>CEP</b> do endereço de cobrança é obrigatório! <br>';
      }
  
      if ($('#address_district').val() === '') {
        msg += 'O campo <b>Bairro</b> do endereço de cobrança é obrigatório! <br>';
      }
  
      if ($('#address_city').val() === '') {
        msg += 'O campo <b>Cidade</b> do endereço de cobrança é obrigatório! <br>';
      }
  
      if ($('#address_state').val() === '') {
        msg += 'O campo <b>Estado</b> do endereço de cobrança é obrigatório! <br>';
      }
    }

    if (this.mandatory.address_delivery) {
      if ($('#address_street_delivery').val() === '') {
        msg += 'O campo <b>Logradouro</b> do endereço de entrega é obrigatório! <br>';
      }
  
      if ($('#address_number_delivery').val() === '') {
        msg += 'O campo <b>Número</b> do endereço de entrega é obrigatório! <br>';
      }
  
      if ($('#address_zipcode_delivery').val() === '') {
        msg += 'O campo <b>CEP</b> do endereço de entrega é obrigatório! <br>';
      }
  
      if ($('#address_district_delivery').val() === '') {
        msg += 'O campo <b>Bairro</b> do endereço de entrega é obrigatório! <br>';
      }
  
      if ($('#address_city_delivery').val() === '') {
        msg += 'O campo <b>Cidade</b> do endereço de entrega é obrigatório! <br>';
      }
  
      if ($('#address_state_delivery').val() === '') {
        msg += 'O campo <b>Estado</b> do endereço de entrega é obrigatório! <br>';
      }
    }

    if (!$('#use_terms_accepted').is(':checked')) {
      msg += 'Os <b>Termos de Uso</b> devem ser aceitos! <br>';
    }

    if (msg !== '') {
      this.toastrService.showWarning(msg);
    } else {
      btn.html('<i class="fa-solid fa-pen-to-square"></i> Salvando...');
      btn.prop('disabled', true);

      this.apiService.createStore('?client_id=' + this.clientId, this.user).subscribe((res) => {
        if (!res['error']) {
          this.toastrService.showSuccess(res['message']);
          this.router.navigate(['/login']);
        } else {
          this.toastrService.showError(res['message']);
        }
      });

      btn.html('<i class="fa-solid fa-pen-to-square"></i> Cadastrar');
      btn.prop('disabled', false);
    }
  }

}
