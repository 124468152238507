import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private db: AngularFirestore,
  ) { }

  getOrderTypes(companyID, cb) {
    let query;
    const result = [];
    query = this.db.collection(`companies/${companyID}/order_types`).ref.orderBy('name');

    query.get().then(res => {
      res.docs.map(m => {
        const data = m.data();
        const id = m.id;
        result.push({data, id});
      });
      cb(null, result);
    }).catch(err => {
      console.log(err);
      cb(err, null);
    });
  }

  getOrderStatus(companyId, statusCode): Observable<any> {
    return this.db.collection(`companies/${companyId}/order_status`).doc(statusCode).get();
  }

  createOrder(companyPdId, data, cb) {
    this.db.collection(`companies/${companyPdId}/sale_orders`).add(data).then((res) => {
      cb(null, res, 'Orçamento criado com sucesso!');
    }).catch((err) => {
      console.log('error', err);
      cb('Desculpe, ocorreu um erro inesperado. Por favor, tente novamente!');
    });
  }
}
